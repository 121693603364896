import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    API_BASE_URLS,
    Environment,
    ENVIRONMENT,
    LicenseNotificationModule,
    ResponseErrorModule,
    RestServiceModule,
    TooltipifyMainModule,
    TranslateModule,
} from 'plmt-core-library';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RestServiceModule,
        TooltipifyMainModule,
        TranslateModule,
        ResponseErrorModule,
        LicenseNotificationModule,
    ],
    providers: [
        {
            provide: API_BASE_URLS,
            useValue: environment.apiBaseUrls,
        },
        {
            provide: ENVIRONMENT,
            useValue: environment.production
                ? Environment.Production
                : Environment.Development,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
