import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authPath, authTokenPath } from 'plmt-core-library';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
    {
        path: authPath,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: authTokenPath,
        loadChildren: () =>
            import('plmt-core-library').then((m) => m.AuthTokenPageModule),
    },
    {
        path: '',
        loadChildren: () =>
            import('./pages/pages.module').then((m) => m.PagesModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
