import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChangeDetectionService } from 'ng-onpush';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private changeDetectionService: ChangeDetectionService,
    ) {}

    ngOnInit(): void {
        this.changeDetectionService.provideRootChangeDetectorRef(
            this.changeDetectorRef,
        );
    }
}
