import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { authRedirect, StorageService } from 'plmt-core-library';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataApiService } from '../api/data-api.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private dataApiService: DataApiService,
        private router: Router,
        private storageService: StorageService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
        const token = this.storageService.authToken.value;

        if (!token) return true;

        return this.dataApiService.page().pipe(
            map(({ row: page }) => {
                if (!page.user) return true;

                const redirect = route.queryParams.redirect as
                    | string
                    | undefined;

                if (redirect) {
                    authRedirect(redirect, token);
                } else {
                    this.router.navigate(['/']);
                }

                return false;
            }),
        );
    }
}
