import { Injectable } from '@angular/core';
import {
    Column,
    ColumnType,
    Dataset,
    ResponseItem,
    RestService,
} from 'plmt-core-library';
import { Observable } from 'rxjs';
import { DataPage } from '../interfaces/data';
import { ProgressType } from '../enums/dataset';
import { map } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DataApiService {
    constructor(private restService: RestService) {}

    page(): Observable<ResponseItem<DataPage>> {
        return this.restService.get('/data/page');
    }

    dataset(data: any): Observable<{ [key: string]: DataDataset | ErrorData }> {
        return this.restService.post('/data/dataset', data);
    }

    merge(data: MergeRequest): Observable<MergeDatasetRequest> {
        return this.restService.post('/data/merge', data);
    }

    formula(data: any): Observable<{
        rows: any;
        base_type: ColumnType;
    }> {
        return this.restService.post('/data/formula', data);
    }

    ping(datasetId: number): Observable<DatasetProgress> {
        return this.restService
            .get<{ [datasetId: number]: DatasetProgress }>(`/dataset/ping`, {
                ids: [datasetId],
            })
            .pipe(map((progressMap) => progressMap[datasetId]));
    }
}

export interface DataDataset {
    rows: {
        [key: string]: any;
    }[];
    columns: DataColumn[];
    dataset: Dataset;
}

export interface DatasetProgress {
    progress_type: ProgressType;
    progress_total: number;
    progress_current: number;
}

export interface DataColumn {
    column: Column;
    key: number;
}

export interface MergeRequest {
    relations: MergeRequestRelation[];
}

export interface MergeDatasetRequest {
    columns: DataColumn[];
    rows: {
        [key: string]: any;
    }[];
}

export interface MergeRequestRelation {
    dataset_id: number;
    foreign_key: number;
    columns: number[];
}

export interface ErrorData {
    code: string;
    httpCode: HttpStatusCode;
    message: string;
    title: string;
}
